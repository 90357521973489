import { api } from "./api";
import {IGetExplodedDrawingsDto} from "../../models/dto/IGetExplodedDrawingsDto";
import {IResultDto} from "../../models/dto/IResultDto";
import {IGetLoggedDto} from "../../models/dto/IGetLoggedDto";
export const authApi = api.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({

        isLoggedUser: build.query<any , {username: string; token: string}>({
            query: ({username,token}) => ({
                url: "islogged",
                params: { username: username, token: token },
            }),
            transformResponse: (baseQueryReturnValue: any, meta) =>
                baseQueryReturnValue,
        }),
        loginManual: build.query<any ,  {username: string; token: string}>({
            query: ({username,token}) => ({
                url: "login",
                params: { username: username, token: token },
            }),
            transformResponse: (baseQueryReturnValue: any, meta) =>
                baseQueryReturnValue,
        }),
    }),
});

export const {
    useIsLoggedUserQuery,
    useLazyLoginManualQuery,
    useLazyIsLoggedUserQuery,

} = authApi;
