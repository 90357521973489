import { api } from "./api";
import {IGetExplodedDrawingsDto} from "../../models/dto/IGetExplodedDrawingsDto";
import {IExplodedDrawings} from "../../models/entities/IExplodedDrawings";
import {IGetDrawingDto} from "../../models/dto/IGetDrawingDto";

const explodedDrawingApi = api.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
        getDrawings: build.query<IGetExplodedDrawingsDto, {username: string; token: string; searchString: string}>({
            query: ({username,token,searchString}) => ({
                url: "search",
                params: { searchString: searchString ,username: username, token: token },
            }),
            transformResponse: (baseQueryReturnValue: IGetExplodedDrawingsDto, meta) =>
                baseQueryReturnValue,
            providesTags: (result) =>
                [{ type: "ExplodedDrawings", id: "ED_List" }],
        }),
    }),
});

export const {
    useGetDrawingsQuery,
    useLazyGetDrawingsQuery,
} = explodedDrawingApi;
