import React, {useEffect, useState} from "react";
import {
    AppBar, AppBarSection, GridLayout, GridLayoutItem,
} from "@progress/kendo-react-layout";
import Layout from "../components/Layout";
import LOGO from "./../img/logo-sabart.png";
import LOGOMTD from "./../img/mtd.jpg";
import CARRARO from "./../img/carraro.png";
import {
    InputClearValue,
    InputPrefix,
    InputSeparator,
    InputSuffix,
    TextBox,
    TextBoxChangeEvent
} from "@progress/kendo-react-inputs";
import {Button} from "@progress/kendo-react-buttons";
import {AutoComplete} from "@progress/kendo-react-dropdowns";
import {AutoCompleteChangeEvent} from "@progress/kendo-react-dropdowns/dist/npm/AutoComplete/AutoCompleteProps";
import { Icon } from "@progress/kendo-react-common";
import {SCREEN_LG, SCREEN_MD, SCREEN_SM, SCREEN_XL, useWindowSize} from "../utils/ScreenSizes";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";

const NIENTE = -1;
const GRIGLIA_ESPLOSI = 0;
const GRIGLIA_PARTI  = 1;
const LASTEST_SEARCHES_MAX_NUMBER = 7;

interface GridPositions {
    col: number;
    row: number,
    colSpan: number ,
    rowSpan: number,
}

const HomePage = () => {

    const windowSize = useWindowSize();
    const [searchString, setSearchString] = useState<string>("");
    const [searchStringDebounced, setSearchStringDebounced] = useState<string>("");
    const [selected, setSelected] = useState<number>(NIENTE);
    const [firstTabDisabled, setFirstTabDisabled] = useState<boolean>(true);
    const [secondTabDisabled, setsSecondTabDisabled] = useState<boolean>(true);

    const [infoDialogOpen, setInfoDialogOpen] = useState<boolean>(true);
    // const [lastestSearches, setLastestSearches]  = useState<string[]>([])
    const [positionLogo, setPositionLogo] = useState<GridPositions>({
        col: 1, colSpan: 1, row: 1, rowSpan: 1
    });
    const [positionLogoCarraro, setPositionLogoCarraro] = useState<GridPositions>({
        col: 3, colSpan: 1, row: 1, rowSpan: 1
    });
    const [positionTextBox, setPositionPositionTextbox] = useState<GridPositions>({
        col: 5, colSpan: 5, row: 1, rowSpan: 1
    });
    const [positionInfoBtn, setPositionPositionInfoBtn] = useState<GridPositions>({
        col: 10, colSpan: 1, row: 1, rowSpan: 1
    });
    const [rowHeight, setRowHeight] = useState<number>(50);
    const [colWidth, setColWidth] = useState<string>("7.2vw");
    const [isMobile, setIsMobile] = useState<boolean>(false)

    //Debouncing per chiamare la getExplodedDrawings solo quando ho finito di scrivere.
    // useEffect(()=>{
    //     if(searchString===""){
    //             setSearchStringDebounced("");
    //             setFirstTabDisabled(true);
    //             setsSecondTabDisabled(true);
    //             setSelected(NIENTE)
    //     }else {
    //         setFirstTabDisabled(false);
    //         setSelected(GRIGLIA_PARTI)
    //         setSearchStringDebounced(searchString);
    //         // const delayDebounceFn = setTimeout(() => {
    //         //         // setFirstTabDisabled(false);
    //         //         // setSelected(GRIGLIA_PARTI)
    //         //         // setSearchStringDebounced(searchString);
    //         //         //inizializzo le ricerche recenti come copia dellle attuali - TENGO PER SVILUPPI FUTURI
    //         //         // let searches = [...lastestSearches];
    //         //         // if(searches.includes(searchString)){
    //         //         //     // se le ricerche recenti includono la stringa attuale, la rimetto in cima all'array
    //         //         //     searches.splice(searches.indexOf(searchString),1)
    //         //         // }
    //         //         // if (searches.length >= LASTEST_SEARCHES_MAX_NUMBER) {
    //         //         //     // se sono troppe allora tolgo la meno recente
    //         //         //     searches.shift();
    //         //         //     searches.push(searchString)
    //         //         // }else{
    //         //         //     searches.push(searchString)
    //         //         // }
    //         //         // setLastestSearches(searches)
    //         //
    //         // }, 1500);
    //         // return () => clearTimeout(delayDebounceFn);
    //     }
    // },[searchString])

    const SearchButton = (props: { search: string }) => {
        return (
            <Button
                className={"bottoni-ricerche-recenti"}
                size={"small"}
                fillMode={"solid"}
                rounded={"full"}
                onClick={()=>setSearchString(props.search)}>
                {props.search}
                <span className="bottoni-ricerche-recenti-icon k-icon k-i-close-outline k-i-x-outline"></span>
            </Button>
        )
    }

    useEffect(()=>{
        if(windowSize >= SCREEN_XL){
            setRowHeight(50);
            setColWidth("7.2vw")
            setIsMobile(false);
            setPositionLogo({...positionLogo, colSpan: 2, row: 1})
            setPositionLogoCarraro({...positionLogoCarraro,col: 3, colSpan: 1, row: 1})
            setPositionPositionTextbox({...positionTextBox,   col: 5, colSpan: 5, row: 1, })
            setPositionPositionInfoBtn({...positionTextBox,   col: 9, row: 1 })
        }
        if(windowSize < SCREEN_XL && windowSize >= SCREEN_LG){
            setRowHeight(50);
            setColWidth("6.8vw")
            setIsMobile(false);
            setPositionLogo({...positionLogo, colSpan: 2, row: 1})
            setPositionLogoCarraro({...positionLogoCarraro,col: 4, colSpan: 1, row: 1})
            setPositionPositionTextbox({...positionTextBox,   col: 6, colSpan: 5, row: 1, })
            setPositionPositionInfoBtn({...positionTextBox,   col: 10, row: 1 })

        }
        if(windowSize < SCREEN_LG && windowSize > SCREEN_MD){
            setRowHeight(40);
            setColWidth("6.8vw")
            setIsMobile(false);
            setPositionLogo({...positionLogo,  colSpan: 2, row: 1})
            setPositionLogoCarraro({...positionLogoCarraro,col: 4, colSpan:1, row: 1})
            setPositionPositionTextbox({...positionTextBox,   col: 6, colSpan: 5, row: 1, })
            setPositionPositionInfoBtn({...positionTextBox,   col: 10, row: 1 })
        }
        if(windowSize < SCREEN_MD ){
            setIsMobile(true);
            setColWidth("40vw")
            setRowHeight(60);

            setPositionLogo({...positionLogo, col: 1, colSpan: 1})
            setPositionLogoCarraro({...positionLogoCarraro, col: 2, colSpan: 1})
            setPositionPositionTextbox({...positionTextBox, colSpan: 2, row: 2, col: 1})

            setPositionPositionInfoBtn({...positionTextBox,  row: 2, col: 3})


        }

    },[windowSize])



    return (
        <div >
            <AppBar style={{backgroundColor: "white"}} >
                <AppBarSection >
                    <GridLayout
                        className={"layout-container"}
                        rows={isMobile ? [
                            {height: rowHeight},
                            {height: rowHeight},
                        ] :
                            [
                            {height: rowHeight},
                        ]}
                        cols={isMobile ? [
                            {width: colWidth},
                            {width: colWidth},
                            {width: colWidth},
                        ] : [
                            {width: colWidth},
                            {width: colWidth},
                            {width: colWidth},
                            {width: colWidth},
                            {width: colWidth},
                            {width: colWidth},
                            {width: colWidth},
                            {width: colWidth},
                            {width: colWidth},
                            {width: colWidth},
                            {width: colWidth},
                            {width: colWidth},
                        ]}
                        gap={{ rows: 0, cols: 18 }}
                    >
                        <GridLayoutItem className={"container-logo"} row={positionLogo.row} col={positionLogo.col}  colSpan={positionLogo.colSpan}>
                            <img className="sabart-logo" src={LOGO} />
                        </GridLayoutItem>
                        <GridLayoutItem className={"container-logo"} row={positionLogoCarraro.row} col={positionLogoCarraro.col}  colSpan={positionLogoCarraro.colSpan}>
                            <img className="carraro-logo" src={CARRARO} />
                        </GridLayoutItem>
                        {/*{searchString && <GridLayoutItem className={"search-bar"} row={1} col={4} colSpan={2}>*/}
                        {/*    <label className={"label-ricerca-corrente"}> Ricerca Corrente: </label>*/}
                        {/*</GridLayoutItem>}*/}
                        <GridLayoutItem className={"container-search-bar search-bar"} row={positionTextBox.row} col={positionTextBox.col} colSpan={positionTextBox.colSpan}>
                            <TextBox
                                size={"large"}
                                className="standard-inputs"
                                fillMode={"flat"}
                                value={searchString}
                                onChange={(e: TextBoxChangeEvent) => {
                                    e.value ? setSearchString(e.value.toString()) : setSearchString("")
                                }}
                                placeholder="Ricerca per Modello Macchina o Codice Parte"
                                prefix={() => (
                                    <>
                                        {
                                            searchString && <InputPrefix className={"input-prefix"} >
                                                Ricerca corrente:
                                            </InputPrefix>
                                        }
                                    </>
                                )}
                                suffix={()=>
                                    <>

                                    {
                                        searchString &&
                                        <InputSuffix >
                                            <Button
                                                className={"bottone-svuota-ricerca"}
                                                size={"large"}
                                                fillMode={"flat"}
                                                value={searchString}
                                                onClick={(e: any) => {
                                                    setSearchString("")
                                                }}
                                                placeholder="Ricerca per numero apparecchio o Numero pezzo"

                                            >
                                                {/*<span  className={"svuota-text"}>Svuota</span>*/}
                                                <Icon name="x" />
                                            </Button>
                                        </InputSuffix >
                                    }
                                        <InputSuffix >
                                            <Button
                                                className={"bottone-ricerca"}
                                                size={"large"}
                                                fillMode={"flat"}
                                                value={searchString}
                                                onClick={(e: any) => {
                                                    setSearchStringDebounced(searchString)
                                                    setFirstTabDisabled(false);
                                                    setsSecondTabDisabled(true);
                                                    setSelected(GRIGLIA_ESPLOSI)
                                                }}
                                                placeholder="Ricerca per numero apparecchio o Numero pezzo"

                                            >
                                                {/*<span  className={"svuota-text"}>Svuota</span>*/}
                                               Cerca
                                            </Button>
                                        </InputSuffix >
                                    </>
                                }
                            />
                        </GridLayoutItem>
                        <GridLayoutItem className={"container-search-bar search-bar"} row={positionInfoBtn.row} col={positionInfoBtn.col}>
                            <Button
                                className={"bottone-info"}
                                size={"small"}
                                fillMode={"flat"}
                                value={searchString}
                                onClick={(e: any) => {
                                    setInfoDialogOpen(!infoDialogOpen)
                                }}
                                placeholder="Come identificare il prdootto carraro?"

                            >
                                {/*<span  className={"svuota-text"}>Svuota</span>*/}
                                ?
                            </Button>
                        </GridLayoutItem>
                        {/*<GridLayoutItem  row={1} col={4} colSpan={7} rowSpan={1} className={"search-bar-lastest"}>*/}
                        {/*   <span className={"label-richerche-recenti"}>Ricerche Recenti:</span>*/}
                        {/*    {lastestSearches.slice(0).reverse().map((el) => {*/}
                        {/*        return <SearchButton search={el}/>*/}
                        {/*    })}*/}

                        {/*</GridLayoutItem>*/}
                    </GridLayout>
                </AppBarSection>
            </AppBar>
            {infoDialogOpen &&
                <Dialog
                    className="custom-dialog"
                    onClose={() => setInfoDialogOpen(!infoDialogOpen)}
                >
                    <button className="custom-dialog-close-button" onClick={() => setInfoDialogOpen(!infoDialogOpen)}>x</button>

                    <div className="custom-dialog-content">
                        <img src="images/find-carraro-product.png" alt="Come identificare il prdootto carraro??"/>
                    </div>
                </Dialog>
            }
            <Layout searchString={searchStringDebounced} selectedTab={selected} firstTabDisabled={firstTabDisabled} secondTabDisabled={secondTabDisabled}/>
        </div>
    );
};

export default HomePage;
