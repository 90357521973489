import { load, loadMessages } from '@progress/kendo-react-intl';

// required data (must be always loaded)
import likelySubTags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

// locale-dependent data
import itNumbers from 'cldr-numbers-full/main/it/numbers.json';
import itCurrency from 'cldr-numbers-full/main/it/currencies.json';
import itTimeZoneNames from 'cldr-dates-full/main/it/timeZoneNames.json';
import itCaGregorian from 'cldr-dates-full/main/it/ca-gregorian.json';
import itDateFields from 'cldr-dates-full/main/it/dateFields.json';
// add here import statements for other CLDR files (more languages)

// localized messages (catalogs)
import itMessages from './Config/it.json';
// add here import statements for other message files (more languages)

export const setupLocalization = () => {
    load(
        likelySubTags,
        currencyData,
        weekData,

        itNumbers,
        itCurrency,
        itTimeZoneNames,
        itCaGregorian,
        itDateFields,

        // add here other CLDR objects
    );

    // Each message collection must be explicitly loaded for each variant of any accepted language tag.

    loadMessages(itMessages, 'it');
    loadMessages(itMessages, 'it-IT');
    loadMessages(itMessages, 'it-CH');
    // add here other message catalogs
};
