import React, {useEffect, useState} from "react";
import {GridRowClickEvent} from "@progress/kendo-react-grid";
import {SCREEN_LG, SCREEN_MD, SCREEN_XL, useWindowSize} from "../utils/ScreenSizes";
import {
    GridLayout,
    GridLayoutItem,
    TabStrip,
    TabStripSelectEventArguments,
    TabStripTab
} from "@progress/kendo-react-layout";
import {TextBox, TextBoxChangeEvent} from "@progress/kendo-react-inputs";
import sample1 from "./../img/sample/sample1.jpg";
import sample2 from "./../img/sample/sample2.jpg";
import {
    useGetDrawingsQuery,
} from "../store/api/explodedDrawing";
import ExplodedDrawingsGrid from "./ExplodedDrawingsGrid";
import {Loader} from "@progress/kendo-react-indicators";
import {skipToken} from "@reduxjs/toolkit/dist/query/react";
import DrawingPartsOFGrid from "./DrawingPartsOFGrid";
import {Button} from "@progress/kendo-react-buttons";
import {Dialog} from "@progress/kendo-react-dialogs";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {setLoggedIn} from "../store/auth";
import {useAppDispatch, useAppSelector} from "../store";
import {IDrawings, IParts} from "../models/entities/IExplodedDrawings";

const NIENTE = -1;
const GRIGLIA_ESPLOSI = 0;
const GRIGLIA_PARTI  = 1;

interface LayoutProps {
    searchString: string,
    selectedTab: number,
    firstTabDisabled: boolean,
    secondTabDisabled: boolean,
}

interface GridPositions {
    col: number;
    row: number,
    colSpan: number ,
    rowSpan: number,
}

const Layout = (props: LayoutProps) =>{
    const loggedUser = useAppSelector((state)=>state.loggedUser)
    const dispatch = useAppDispatch();

    const [rowHeight, setRowHeight] =  useState<string>("88vh");
    const [colWidth, setColWidth] = useState<string>("7.2vw");
    const [maxWidthDrawingGrid, setMaxWidthDrawingGrid] = useState<string>("100vw");
    const [maxWidthPartsOf, setMaxWidthPartsOf] = useState<string>("38vw");

    const [positionGridDrawing, setPositionGridDrawing] = useState<GridPositions>({
        col: 1, colSpan: 12, row: 1, rowSpan: 1
    })
    const [positionDrawing, setPositionDrawing] = useState<GridPositions>({
        col: 6, colSpan: 7, row: 1, rowSpan: 2
    })

    const [singleRow, setSingleRow] = useState<boolean>(true);



    const windowSize = useWindowSize();

    const [fileNameToSearch, setFileNameToSearch] = useState<string|undefined>(undefined);

    const {
        data: Raw_Drawings,
        isFetching,
        isError,
        error: dataErrorRawDrawing} = useGetDrawingsQuery(props.searchString !== "" ? {
            username: loggedUser.username, searchString: props.searchString, token: loggedUser.token} : skipToken);

    const [formattedExplodedDrawings, setFormattedExplodedDrawings] = useState<IDrawings[]>([]);
    const [formattedExplodedParts, setFormattedExplodedParts] = useState<IParts[]>([]);

    const [dataGridExploed, setDataGridExploded] = useState<any[]>([]);
    const [dataGridParts, setDataGridParts] = useState<any[]>([]);
    const [imageURL, setImageURL] = useState<string|undefined>(undefined);
    const [imageSize, setImageSize] = useState<number>(0);

    const [selected, setSelected] = useState<number>(props.selectedTab);
    const [firstTabDisabled, setFirstTabDisabled] = useState<boolean>(true);
    const [secondTabDisabled, setSecondTabDisabled] = useState<boolean>(true);


    const [zoomedDrawing, setZoomedDrawing] = useState<boolean>(false);


    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
    };

    const handleRowClickExploded = (e: GridRowClickEvent) =>{
        if(e.dataItem){
            setSelected(GRIGLIA_PARTI);
            setPositionGridDrawing({...positionGridDrawing, colSpan: 5})
            setSecondTabDisabled(false);
            setImageURL(e.dataItem.svg_url);
            setFormattedExplodedParts(e.dataItem.parts)
        }
    }


    useEffect(()=>{
        if(Raw_Drawings && Raw_Drawings.bResult == true) {
            setFormattedExplodedDrawings(Raw_Drawings.explodedDrawings.drawings);
        }else{
            if(isError && dataErrorRawDrawing) {

                // @ts-ignore
                if(dataErrorDrawing.data.aMessage[0].nCode === 7){
                    dispatch(setLoggedIn(false));
                }else{
                    setDataGridExploded([]);
                }
            }else{
                setDataGridExploded([]);
            }
        }
    },[Raw_Drawings])


    useEffect(() => {
        if(imageURL) {
            const img = new Image();
            img.src = imageURL;
            img.onload = () =>{
                setImageSize(Math.ceil(img.width * 0.1))
            }
        }
    }, [imageURL]);


    useEffect(()=>{
        setFirstTabDisabled(props.firstTabDisabled);
    },[props.firstTabDisabled])

    useEffect(()=>{
        setSecondTabDisabled(props.secondTabDisabled);
    },[props.secondTabDisabled])

    useEffect(()=>{
        setSelected(props.selectedTab);
    },[props.selectedTab])

    useEffect(()=>{
        setSelected(GRIGLIA_ESPLOSI);
    },[props.searchString])



    //-----------------------Gestione layout responsive
    useEffect(()=>{
        if(selected === GRIGLIA_ESPLOSI){
            setPositionGridDrawing({...positionGridDrawing, colSpan: 12})
            setSingleRow(true);
        }else{

            if(windowSize >= 1921){
                setSingleRow(true);
                setPositionGridDrawing({...positionGridDrawing, colSpan: 6})
                setPositionDrawing({...positionDrawing,col: 7, colSpan: 7})
            }else if(windowSize >= SCREEN_XL && windowSize < 1921){
                setSingleRow(true);
                setPositionGridDrawing({...positionGridDrawing, colSpan: 5})
            }else if(windowSize < SCREEN_XL && windowSize >= SCREEN_LG){
                setSingleRow(true);
                setPositionGridDrawing({...positionGridDrawing, colSpan: 6})
            }else if(windowSize < SCREEN_LG && windowSize >= SCREEN_MD){
                setSingleRow(false);
                setPositionGridDrawing({...positionGridDrawing, colSpan: 12})
                setPositionDrawing({...positionDrawing,row: 2, col: 1, colSpan: 12})
            }else if(windowSize < SCREEN_MD ){
                setSingleRow(false);
                setPositionGridDrawing({...positionGridDrawing, colSpan: 12})
                setPositionDrawing({...positionDrawing,row: 2, col: 1, colSpan: 12})
            }
        }
    },[selected])

    useEffect(()=>{
        // console.log("windowSize",windowSize)
        if(windowSize >= (1921)){
            setSingleRow(true);
            setColWidth("7.4vw")
            setMaxWidthDrawingGrid("100vw");
            setMaxWidthPartsOf("46vw");
            if(selected === GRIGLIA_ESPLOSI) {
                setPositionGridDrawing({...positionGridDrawing, colSpan: 12})
            }else {
                setPositionGridDrawing({...positionGridDrawing, colSpan: 7})
            }
            setPositionDrawing({...positionDrawing,row: 1 ,col:7, colSpan: 7})
        }else if(windowSize >= SCREEN_XL && windowSize < 1921){
            setSingleRow(true);
            setColWidth("7.2vw")
            setMaxWidthDrawingGrid("100vw");
            setMaxWidthPartsOf("38vw");
            if(selected === GRIGLIA_ESPLOSI) {
                setPositionGridDrawing({...positionGridDrawing, colSpan: 12})
            }else {
                setPositionGridDrawing({...positionGridDrawing, colSpan: 5})
            }
            setPositionDrawing({...positionDrawing,row: 1,col: 6, colSpan: 7})
        }else if(windowSize < SCREEN_XL && windowSize >= SCREEN_LG){
            setSingleRow(true);
            setRowHeight("80vh")
            setColWidth("6.8vw")
            setMaxWidthDrawingGrid("92vw");
            setMaxWidthPartsOf("44vw");
            if(selected === GRIGLIA_ESPLOSI) {
                setPositionGridDrawing({...positionGridDrawing, colSpan: 12})
            }else {
                setPositionGridDrawing({...positionGridDrawing, colSpan: 6})
            }
            setPositionDrawing({...positionDrawing,row: 1,  col: 7, colSpan: 6})
        }else if(windowSize < SCREEN_LG && windowSize >= SCREEN_MD){
            setPositionGridDrawing({...positionGridDrawing, colSpan: 12})
            setPositionDrawing({...positionDrawing,row: 2, col: 1, colSpan: 12})
            setMaxWidthDrawingGrid("92vw");
            setMaxWidthPartsOf("92vw");
            setRowHeight("90vh")
            setColWidth("6.5vw")
        }else if(windowSize < SCREEN_MD ){
            setPositionGridDrawing({...positionGridDrawing, colSpan: 12})
            setPositionDrawing({...positionDrawing,row: 2, col: 1, colSpan: 12})
            setMaxWidthDrawingGrid("92vw");
            setMaxWidthPartsOf("92vw");
            setRowHeight("90vh")
            setColWidth("6vw")
        }

    },[windowSize])
    //-----------------------fine gestione layout responsive

    return (
        <>
            <GridLayout
                className={"layout-container"}
                rows={
                    [
                        {height: rowHeight},
                        {height: singleRow? "0vh" : rowHeight},
                    ]
                }
                cols={[
                    {width: colWidth},
                    {width: colWidth},
                    {width: colWidth},
                    {width: colWidth},
                    {width: colWidth},
                    {width: colWidth},
                    {width: colWidth},
                    {width: colWidth},
                    {width: colWidth},
                    {width: colWidth},
                    {width: colWidth},
                    {width: colWidth},
                ]}
                gap={{ rows: 0, cols: 18 }}
            >

                <GridLayoutItem row={positionGridDrawing.row} col={positionGridDrawing.col} colSpan={positionGridDrawing.colSpan}>
                    <TabStrip selected={selected} onSelect={handleSelect}>

                        <TabStripTab title={"Esplosi"} disabled={firstTabDisabled} contentClassName={"tabstrip-content"}>
                            {isFetching ?
                                <Loader size={"large"} type={"infinite-spinner"}/>
                                :
                                formattedExplodedDrawings.length > 0 && !isError ?
                                    <ExplodedDrawingsGrid
                                        maxWidth={maxWidthDrawingGrid}
                                        data={formattedExplodedDrawings}
                                        onRowClick={(e)=>handleRowClickExploded(e)}
                                    />
                                    : <h3>Nessun risultato</h3>
                            }


                        </TabStripTab>
                        <TabStripTab title={"Parti"} disabled={secondTabDisabled} contentClassName={"tabstrip-content"}>

                            {formattedExplodedParts.length ? <DrawingPartsOFGrid
                                maxWidth={maxWidthPartsOf}
                                data={formattedExplodedParts}
                            /> : <h3>Nessun risultato</h3>
                            }
                        </TabStripTab>

                    </TabStrip>

                </GridLayoutItem>

                { selected === GRIGLIA_PARTI && <GridLayoutItem row={positionDrawing.row} col={positionDrawing.col} rowSpan={positionDrawing.rowSpan} colSpan={positionDrawing.colSpan} className={"img-esploso-container"}>
                    {
                        <>
                            {imageSize && <TransformWrapper
                                centerOnInit={positionDrawing.row != 2 }
                                initialScale={positionDrawing.row == 2 ? 0.2 : 1}
                                initialPositionY={positionDrawing.row == 2 ? 0 : undefined}
                                initialPositionX={imageSize < 300 ? 400 : imageSize} // un miglior "centraggio"
                                minScale={0.2}
                                limitToBounds={false}
                            >
                                {({zoomIn, zoomOut, resetTransform, ...rest}) => (
                                    <>
                                        {imageURL && <div className="tools">
                                            <Button onClick={() => zoomIn()} icon={"plus"}></Button>
                                            <Button onClick={() => zoomOut()} icon={"minus"}></Button>
                                            <Button onClick={() => resetTransform()} icon={"reset-sm"}></Button>
                                        </div>}
                                        <TransformComponent>
                                            <img src={imageURL} className={"img-esploso"}/>
                                        </TransformComponent>
                                    </>
                                )}
                            </TransformWrapper>}
                            {/*{imageURL && <Button icon="zoom-in" size={"large"} className={"zoom-button"}*/}
                            {/*                     onClick={() => setZoomedDrawing(true)}/>}*/}
                            {/*<Button icon="zoom-in" size={"large"} className={"zoom-button"} />*/}
                        </>
                    }
                </GridLayoutItem>}
            </GridLayout>
            {zoomedDrawing &&
                <Dialog
                    className={"zoom-dialog"}
                    // title={"nome esploso"}
                    onClose={()=>setZoomedDrawing(false)}
                    width={"100vw"}
                    height={"100vh"}
                >
                    <div   className={"img-container-zoomed"}>
                        <img src={imageURL} className={"img-esploso-zoomed"}/>
                        <Button icon="zoom-out" size={"large"} className={"zoom-button-zoomed"} onClick={()=>setZoomedDrawing(false)}/>
                    </div>
                </Dialog>
            }
        </>

    );
}

export default Layout;
