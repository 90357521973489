import { createSlice,  PayloadAction } from "@reduxjs/toolkit";


interface AuthState {
    loggedIn: boolean;
}

const initialState: AuthState = {
    loggedIn: false,
};


const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setLoggedIn(state, action: PayloadAction<boolean>) {
            state.loggedIn = action.payload;
        },
    },
});

export const { setLoggedIn } =
    authSlice.actions;

export default authSlice.reducer;
