import React from "react";
import LOGO from "../img/logo-sabart.png";

const NoAuth = () => {


    return (
        <div className={"no-auth-page"} style={{textAlign:"center"}}>

            <img className="sabart-logo-no-auth" src={LOGO} />
            <br />
            <br />
            <h2 className={"title-no-auth"}>Autenticazione fallita </h2>
            <br />

            <p className={"text-no-auth"}>
                <label>La preghiamo di effettuare nuovamente l'accesso tramite il portale
                    {/*tramite il <a href={"www.google.com"} target={"_blank"}>link</a>*/}
                </label>
            </p>

            <br />
            <br />


            <img className="sabart-logo-no-auth" src={LOGO} />
        </div>
    );
};

export default NoAuth;
