import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import AppWrapper from "./App";
import reportWebVitals from "./reportWebVitals";
// import "./css/sabart-esplosi-frontend-bootstrap.css";
import "./css/generic.css";
import "./css/kendo-overwrite.css";
import "./css/custom-components.css";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppWrapper />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
