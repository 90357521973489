import React, {useEffect, useState} from 'react';
import {IntlProvider, LocalizationProvider} from "@progress/kendo-react-intl";
import '@progress/kendo-theme-material/dist/all.css';
import {Provider} from "react-redux";
import {RootState, store, useAppDispatch, useAppSelector} from "./store";
import {
    Route,
    Switch,
    useHistory,
    useLocation,
} from "react-router-dom";
import HomePage from "./pages/home";
import NoAuth from "./pages/NoAuth";
import {useIsLoggedUserQuery, useLazyIsLoggedUserQuery, useLazyLoginManualQuery} from "./store/api/auth";
import {setupLocalization} from "./l10n";
import LoginPage from "./pages/LoginPage";
import {skipToken} from "@reduxjs/toolkit/dist/query/react";
import { setLoggedUser } from './store/loggedUser';

function App() {
    setupLocalization();


    const dispatch = useAppDispatch();
    const history = useHistory();
    const [params, setParams] = useState<{username: string; token: string}| null>(null);
    // const dispatch = useAppDispatch();
    const [isLogged,{data: isLoggedData, error: dataError}] = useLazyIsLoggedUserQuery();
    const [login , {data: ResultLogin}] = useLazyLoginManualQuery()

    useEffect(()=>{
        if(window.location.pathname !== "/login"){
            if(params) {
                isLogged(params)
            }else{
                history.push("/no-auth");
            }


        }else{
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const username = urlParams.get('username');
            const token = urlParams.get('token');
            if(username && token) {
                setParams({
                    username: username as string,
                    token: token as string,
                })
                login({
                    username: username as string,
                    token: token as string
                })
            }
        }

    },[window.location.pathname])

    useEffect(() => {
        if(isLoggedData) {
            history.push("/home");
        }
        if(dataError){
            history.push("/no-auth");
        }
    }, [isLoggedData,dataError]);

    useEffect(() => {

        if (ResultLogin) {
            if(ResultLogin.redirect){
                if(ResultLogin.redirect !== "/no-auth" && params){
                    dispatch(setLoggedUser(params))
                }
                history.push(ResultLogin.redirect);
            }
        }
    }, [ResultLogin]);

    return (

        <Switch>
            <Route path="/login" component={LoginPage}></Route>
            <Route path="/home"  component={HomePage}></Route>
            <Route path="/no-auth" component={NoAuth}></Route>
        </Switch>
    );
}

function AppWrapper() {
    return (
        <LocalizationProvider language="it-IT">
            <IntlProvider locale={"it"}>
                <Provider store={store}>
                    <App />
                </Provider>
            </IntlProvider>
        </LocalizationProvider>
    );
}


export default AppWrapper;
