import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface explodedDrawingState {
    currentSearchString: string;
    currentDrawings: any[];
    currentPartsOf: any[]
}

const initialState: explodedDrawingState = {
    currentSearchString: "",
    currentDrawings: [],
    currentPartsOf: [],
};


const explodedDrawingSlice = createSlice({
    name: "explodedDrawing",
    initialState,
    reducers: {
        changeSearch(state, action: PayloadAction<string>) {
            state.currentSearchString = action.payload ?? ""
        }
    },
    // extraReducers: (builder) => {
    //     builder
    //         .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
    //             localStorage.setItem("token", action.payload.token);
    //             state.jwt = action.payload.token;
    //             state.loggedIn = true;
    //         })
    // },
});


export default explodedDrawingSlice.reducer;
