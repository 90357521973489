import React from "react";
import {Loader} from "@progress/kendo-react-indicators";


const LoginPage = () => {



    return    <Loader size={"large"} type={"infinite-spinner"} style={{position: 'absolute', top: '40%', left: '49%'}}/>
};

export default LoginPage;
